import React from 'react';
import { Box, Typography, Drawer, FormControlLabel, Switch } from '@mui/material';

const Sidebar = ({ settingsOpen, onSettingsClose, onThemeChange, isDarkMode }) => (
  <Drawer
    anchor="left"
    open={settingsOpen}
    onClose={onSettingsClose}
    transitionDuration={500}
  >
    <Box p={2} role="presentation" width={250}>
      <Typography variant="h6" gutterBottom>
        Settings
      </Typography>
      <Box mt={2}>
        <FormControlLabel
          control={<Switch checked={isDarkMode} onChange={onThemeChange} />}
          label="Dark Mode"
        />
        {/* Add other settings here as needed */}
      </Box>
    </Box>
  </Drawer>
);

export default Sidebar;
