import React, { useRef } from 'react';
import { Button, Container, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Background = styled('div')({
  height: '100vh',
  width: '100%',
  backgroundImage: 'url(/background_vne.png)', // use the background image from the public directory
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  marginTop: '20px',
  animation: 'fadeIn 2s ease-in-out',
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
});

const StyledButton = styled(Button)({
  borderRadius: '25px',
  border: '2px solid white', // Make button border transparent or match background if needed
  color: 'white', // Set the text color to white
  backgroundColor: 'darkblue',
  padding: '10px 20px',
  fontSize: '18px',
  textShadow: '1px 1px 0 black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black', // Create black text border
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Change the hover background color to a light white
  },
});

const HiddenInput = styled('input')({
  display: 'none',
});

const LandingPage = ({ onLoadJson, onCreateNewStory }) => {
  const fileInputRef = useRef(null);

  const handleLoadJsonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onLoadJson(file);
    }
  };

  return (
    <Background>
      <Container>
        <Typography variant="h2" color="white" textAlign="center" gutterBottom>
          Welcome to Visual Novel Editor
        </Typography>
        <ButtonContainer>
          <StyledButton onClick={handleLoadJsonClick}>
            Load JSON
          </StyledButton>
          <StyledButton onClick={onCreateNewStory}>
            Create a New Story
          </StyledButton>
          <HiddenInput
            type="file"
            accept=".json"
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </ButtonContainer>
      </Container>
    </Background>
  );
};

export default LandingPage;
