import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

const body = document.body;
const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
      if (body.classList.contains('dark-mode')) {
        body.style.backgroundColor = '#121212';
        body.style.color = '#fff';
      } else {
        body.style.backgroundColor = '#fff';
        body.style.color = '#000';
      }
    }
  });
});

observer.observe(body, { attributes: true });
