import React from 'react';
import { Card, CardContent, TextField, IconButton, Typography, Grid, Box, Button, useTheme } from '@mui/material';
import { Delete, Tag } from '@mui/icons-material';
import { useDrag, useDrop } from 'react-dnd';

const Paragraph = ({ paragraph, index, moveParagraph, handleChange, handleToggleTag, handleDeleteParagraph }) => {
  const theme = useTheme();
  const ref = React.useRef(null);

  const [, drag] = useDrag({
    type: 'paragraph',
    item: { index },
  });

  const [, drop] = useDrop({
    accept: 'paragraph',
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveParagraph(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <Card
      ref={node => drag(drop(node))}
      style={{
        marginBottom: '10px',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      }}
    >
      <CardContent>
        <Typography variant="caption" display="block" gutterBottom>
          {paragraph.id}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TextField
            fullWidth
            label="Text"
            name="text"
            value={paragraph.text}
            onChange={(e) => handleChange(e, index)}
            variant="outlined"
            multiline
            margin="normal"
            InputLabelProps={{
              style: { color: theme.palette.text.primary },
            }}
            InputProps={{
              style: { color: theme.palette.text.primary },
            }}
          />
          <IconButton onClick={() => handleToggleTag(index)} size="small">
            <Tag />
          </IconButton>
          <IconButton onClick={() => handleDeleteParagraph(index)} size="small" color="error">
            <Delete />
          </IconButton>
        </Box>
        <TextField
          fullWidth
          label="Character Image"
          name="characterImage"
          value={paragraph.characterImage}
          onChange={(e) => handleChange(e, index)}
          variant="outlined"
          margin="normal"
          InputLabelProps={{
            style: { color: theme.palette.text.primary },
          }}
          InputProps={{
            style: { color: theme.palette.text.primary },
          }}
        />
        <TextField
          fullWidth
          label="Sound"
          name="sound"
          value={paragraph.sound}
          onChange={(e) => handleChange(e, index)}
          variant="outlined"
          margin="normal"
          InputLabelProps={{
            style: { color: theme.palette.text.primary },
          }}
          InputProps={{
            style: { color: theme.palette.text.primary },
          }}
        />
        {paragraph.tag !== undefined && (
          <TextField
            fullWidth
            label="Tag"
            name="tag"
            value={paragraph.tag}
            onChange={(e) => handleChange(e, index)}
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              style: { color: theme.palette.text.primary },
            }}
            InputProps={{
              style: { color: theme.palette.text.primary },
            }}
          />
        )}
        <Box sx={{ marginBottom: '10px' }}>
          {paragraph.choices.map((choice, choiceIndex) => (
            <Grid container spacing={1} key={choiceIndex}>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  label="Choice Text"
                  name="text"
                  value={choice.text}
                  onChange={(e) => handleChange(e, index, choiceIndex)}
                  variant="outlined"
                  InputLabelProps={{
                    style: { color: theme.palette.text.primary },
                  }}
                  InputProps={{
                    style: { color: theme.palette.text.primary },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Next Paragraph"
                  name="nextParagraph"
                  value={choice.nextParagraph}
                  onChange={(e) => handleChange(e, index, choiceIndex)}
                  variant="outlined"
                  InputLabelProps={{
                    style: { color: theme.palette.text.primary },
                  }}
                  InputProps={{
                    style: { color: theme.palette.text.primary },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  color="secondary"
                  onClick={() => handleChange({ target: { name: 'removeChoice' } }, index, choiceIndex)}
                >
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            variant="contained"
            onClick={() => handleChange({ target: { name: 'addChoice' } }, index)}
            style={{ marginTop: '10px' }}
          >
            Add Choice
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Paragraph;
