import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Container, Button, TextField, Fab, Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Save, Add, ArrowDownward } from '@mui/icons-material';
import update from 'immutability-helper';
import Paragraph from './Paragraph';

const Editor = ({ newProjectOpen, onNewProjectClose, fileData }) => {
  const [data, setData] = useState(fileData || null);
  const [filterTag, setFilterTag] = useState('');
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [newProjectId, setNewProjectId] = useState('');
  const [newProjectBackground, setNewProjectBackground] = useState('');
  const [newProjectBackgroundSound, setNewProjectBackgroundSound] = useState('');
  const framesEndRef = useRef(null);

  useEffect(() => {
    if (fileData) {
      setData(fileData);
    }
  }, [fileData]);

  const handleUndo = useCallback(() => {
    if (undoStack.length > 0) {
      const previousState = undoStack.pop();
      setRedoStack((redoStack) => [...redoStack, JSON.parse(JSON.stringify(data))]);
      setData(previousState);
    }
  }, [undoStack, redoStack, data]);

  const handleRedo = useCallback(() => {
    if (redoStack.length > 0) {
      const nextState = redoStack.pop();
      setUndoStack((undoStack) => [...undoStack, JSON.parse(JSON.stringify(data))]);
      setData(nextState);
    }
  }, [redoStack, undoStack, data]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'z') {
        handleUndo();
      } else if ((e.ctrlKey || e.metaKey) && e.key === 'y') {
        handleRedo();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleUndo, handleRedo]);


  const handleChange = (e, index, choiceIndex = null) => {
    const { name, value } = e.target;
    const updatedData = { ...data };

    // Push the current state to the undo stack before making changes
    setUndoStack((undoStack) => [...undoStack, JSON.parse(JSON.stringify(data))]);
    setRedoStack([]);

    if (name === 'addChoice') {
      updatedData.paragraphs[index].choices.push({ text: '', nextParagraph: '' });
    } else if (name === 'removeChoice') {
      updatedData.paragraphs[index].choices.splice(choiceIndex, 1);
    } else if (name === 'toggleTag') {
      if (updatedData.paragraphs[index].tag !== undefined) {
        delete updatedData.paragraphs[index].tag;
      } else {
        updatedData.paragraphs[index].tag = '';
      }
    } else if (choiceIndex !== null) {
      updatedData.paragraphs[index].choices[choiceIndex][name] = value;
    } else {
      updatedData.paragraphs[index][name] = value;
    }

    setData(updatedData);
  };

  const handleToggleTag = (index) => {
    const updatedData = { ...data };
    if (updatedData.paragraphs[index].tag !== undefined) {
      delete updatedData.paragraphs[index].tag;
    } else {
      updatedData.paragraphs[index].tag = '';
    }
    setData(updatedData);
  };

  const handleDeleteParagraph = (index) => {
    const paragraphId = data.paragraphs[index].id;

    const isReferenced = data.paragraphs.some(paragraph =>
      paragraph.choices.some(choice => choice.nextParagraph === paragraphId)
    );

    if (isReferenced) {
      alert(`Paragraph ${paragraphId} is referenced in another paragraph's choices.`);
    }

    const updatedParagraphs = data.paragraphs.filter((_, i) => i !== index);

    setUndoStack((undoStack) => [...undoStack, JSON.parse(JSON.stringify(data))]);
    setRedoStack([]);
    setData({ ...data, paragraphs: updatedParagraphs });
  };

  const moveParagraph = (fromIndex, toIndex) => {
    const updatedParagraphs = update(data.paragraphs, {
      $splice: [
        [fromIndex, 1],
        [toIndex, 0, data.paragraphs[fromIndex]],
      ],
    });

    // Push the current state to the undo stack before making changes
    setUndoStack((undoStack) => [...undoStack, JSON.parse(JSON.stringify(data))]);
    setRedoStack([]);

    setData({ ...data, paragraphs: updatedParagraphs });
  };

  const addNewParagraph = () => {
    const newParagraph = {
      id: `para${data.paragraphs.length + 1}`,
      text: '',
      characterImage: '',
      sound: '',
      choices: [],
      tag: '',
      backgroundColor: '',
    };

    // Push the current state to the undo stack before making changes
    setUndoStack((undoStack) => [...undoStack, JSON.parse(JSON.stringify(data))]);
    setRedoStack([]);

    setData({ ...data, paragraphs: [...data.paragraphs, newParagraph] });

    setTimeout(() => {
      framesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const saveJSON = () => {
    const jsonString = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'visual-novel.json';
    link.click();
  };

  const filterParagraphs = (paragraphs) => {
    if (!filterTag) return paragraphs;
    return paragraphs.filter(paragraph => paragraph.tag && paragraph.tag.includes(filterTag));
  };

  const handleCreateNewProject = () => {
    const newProject = {
      id: newProjectId,
      background: newProjectBackground,
      backgroundSound: newProjectBackgroundSound,
      useShaderBackground: false,
      paragraphs: [
        {
          id: "para1",
          text: "Hello World!",
          characterImage: '',
          sound: '',
          choices: [],
          shake: false
        }
      ]
    };

    setData(newProject);
    setUndoStack([]);
    setRedoStack([]);
    onNewProjectClose();
  };

  return (
    <Container>
      <Box my={2}>
        <TextField
          label="Filter by Tag"
          value={filterTag}
          onChange={(e) => setFilterTag(e.target.value)}
          variant="outlined"
          fullWidth
          style={{ marginBottom: '20px' }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => framesEndRef.current.scrollIntoView({ behavior: 'smooth' })}
          style={{ marginBottom: '20px' }}
          startIcon={<ArrowDownward />}
        >
          Go to Last Paragraph
        </Button>
        {data && (
          <DndProvider backend={HTML5Backend}>
            <div>
              {filterParagraphs(data.paragraphs).map((paragraph, index) => (
                <Paragraph
                  key={paragraph.id}
                  index={index}
                  paragraph={paragraph}
                  moveParagraph={moveParagraph}
                  handleChange={handleChange}
                  handleToggleTag={handleToggleTag}
                  handleDeleteParagraph={handleDeleteParagraph}
                />
              ))}
              <div ref={framesEndRef} />
            </div>
          </DndProvider>
        )}
        <Button
          variant="contained"
          color="primary"
          startIcon={<Save />}
          onClick={saveJSON}
          style={{ marginTop: '20px' }}
        >
          Save JSON
        </Button>
        {data && (
          <Fab
            color="primary"
            aria-label="add"
            onClick={addNewParagraph}
            style={{ position: 'fixed', bottom: '80px', right: '20px' }}
          >
            <Add />
          </Fab>
        )}
      </Box>

      <Dialog open={newProjectOpen} onClose={onNewProjectClose}>
        <DialogTitle>Create New Project</DialogTitle>
        <DialogContent>
          <TextField
            label="Scene ID"
            value={newProjectId}
            onChange={(e) => setNewProjectId(e.target.value)}
            variant="outlined"
            fullWidth
            style={{ marginBottom: '20px' }}
          />
          <TextField
            label="Background"
            value={newProjectBackground}
            onChange={(e) => setNewProjectBackground(e.target.value)}
            variant="outlined"
            fullWidth
            style={{ marginBottom: '20px' }}
          />
          <TextField
            label="Background Sound"
            value={newProjectBackgroundSound}
            onChange={(e) => setNewProjectBackgroundSound(e.target.value)}
            variant="outlined"
            fullWidth
            style={{ marginBottom: '20px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onNewProjectClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateNewProject} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Editor;
