import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => (
  <Box mt={2} py={2} bgcolor="background.paper" textAlign="center">
    <Typography variant="body2" color="textSecondary">
      © 2024 Visual Novel Editor. All rights reserved.
    </Typography>
  </Box>
);

export default Footer;
