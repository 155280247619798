import React, { useState } from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import Editor from './Editor';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import LandingPage from './LandingPage';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [newProjectOpen, setNewProjectOpen] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [fileData, setFileData] = useState(null);

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const handleThemeChange = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleSettingsOpen = () => {
    setSettingsOpen(!settingsOpen);
  };

  const toggleNewProjectOpen = () => {
    setNewProjectOpen(!newProjectOpen);
  };

  const handleLoadJson = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setFileData(JSON.parse(event.target.result));
      setShowEditor(true);
    };
    reader.readAsText(file);
  };

  const handleCreateNewStory = () => {
    setNewProjectOpen(true);
    setShowEditor(true);
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      {showEditor ? (
        <>
          <Header onSettingsClick={toggleSettingsOpen} onNewProjectClick={toggleNewProjectOpen} />
          <Sidebar
            settingsOpen={settingsOpen}
            onSettingsClose={toggleSettingsOpen}
            onThemeChange={handleThemeChange}
            isDarkMode={isDarkMode}
          />
          <Editor
            newProjectOpen={newProjectOpen}
            onNewProjectClose={toggleNewProjectOpen}
            fileData={fileData}
          />
          <Footer />
        </>
      ) : (
        <LandingPage onLoadJson={handleLoadJson} onCreateNewStory={handleCreateNewStory} />
      )}
    </ThemeProvider>
  );
}

export default App;
