import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { Add, Settings } from '@mui/icons-material';

const Header = ({ onSettingsClick, onNewProjectClick }) => (
  <AppBar position="static">
    <Toolbar>
      <Typography variant="h6" style={{ flexGrow: 1 }}>
        Visual Novel Editor
      </Typography>
      <IconButton color="inherit" onClick={onSettingsClick}>
        <Settings />
      </IconButton>
      <IconButton color="inherit" onClick={onNewProjectClick}>
        <Add />
      </IconButton>
    </Toolbar>
  </AppBar>
);

export default Header;
